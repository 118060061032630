import React from 'react';
import Header from '/src/components/layout/header/header';
import Footer from '/src/components/layout/footer/footer';
import { Main } from './styles';
import { Outlet } from 'react-router-dom';

function PageWrapper(prop) {
  return (
    <>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </>
  );
}

export default PageWrapper;
