const gallery = [
  {
    id: 0,
    src: '/images/gallery/1.jpg',
    alt: 'фото кота 1'
  },
  {
    id: 1,
    src: '/images/gallery/2.jpg',
    alt: 'фото кота 2'
  },
  {
    id: 2,
    src: '/images/gallery/3.jpg',
    alt: 'фото кота 3'
  },
  {
    id: 3,
    src: '/images/gallery/4.jpg',
    alt: 'фото кота 4'
  },
  {
    id: 4,
    src: '/images/gallery/1.jpg',
    alt: 'фото кота 5'
  },
  {
    id: 5,
    src: '/images/gallery/2.jpg',
    alt: 'фото кота 6'
  },
  {
    id: 6,
    src: '/images/gallery/3.jpg',
    alt: 'фото кота 7'
  },
  {
    id: 7,
    src: '/images/gallery/4.jpg',
    alt: 'фото кота 8'
  }
];

export default gallery;
